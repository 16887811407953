import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Démarche`}</h2>
    <p>{`Au travers de la pluralité des images, nouer une nouvelle relation au réel et à l’imaginaire afin de se soustraire à l’influence des mots, d’appréhender les champs de couleur (colorfield), l’espace et le temps comme un tout.`}</p>
    <h3>{`Influences`}</h3>
    <p><strong parentName="p">{`Peinture, gravure`}</strong>{` Mark Rothko, Paul Klee, Cobra (Pierre Alechinsky, Willem de Kooning), Henri Michaux |
`}<strong parentName="p">{`Bande dessinée`}</strong>{` Moebius, Singelin, Boulet |
`}<strong parentName="p">{`Typographie`}</strong>{` David Carson |
`}<strong parentName="p">{`Vidéo`}</strong>{` Bill Viola |
`}<strong parentName="p">{`Cinéma`}</strong>{` Jim Jarmusch`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      